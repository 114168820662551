.commentsInfoStripe {
  padding: 7px 5px;
  background-image: url(https://i.dailymail.co.uk/i/furniture/comments/articles/comment_ticker.png);
  margin: 5px 0;
  position: relative;
  font-size: 14px !important;

  a {
    color: #000;
  }
}

.molStatement {
  background: #f9f9f9;
  box-sizing: border-box;
  color: #666;
  border: 1px solid #ececec;
  padding: 5px;
  height: 40px;
  font-weight: 700;
}

.articleCommentsWrapper {
  position: relative;
  width: 100%;

  .transparent {
    opacity: 0.3;
  }

  .articleCommentsContainer {
    min-height: 120px;
  }

  .articleCommentsContainerEmpty {
    border-top: none;
    min-height: unset;
  }
}
