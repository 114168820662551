:global {
  .article-text #most-read-news-wrapper {
    margin-top: 15px;
  }

  #most-read-news {
    font-family: var(--mol-default-font-family);

    &.new {
      height: 170px;

      .rotator-prev,
      .rotator-next {
        top: 36px;
      }

      .bg {
        background: #efefef;
      }

      .rotator-prev {
        left: 0;
      }

      .rotator-next {
        right: 0;
      }

      .rotator-panels {
        top: 10px;
      }

      .rotator-pages {
        ul {
          margin-bottom: 8px;
          padding: 5px 0 5px 15px;
          right: 5px;
          top: 6px;
        }

        .linkro-wocc {
          &.link-wocc a,
          a:hover {
            opacity: 1;
          }
        }
      }

      .linkro-wocc.link-wocc,
      .rotator-pages .linkro-wocc {
        a {
          color: #fff;
        }
      }

      .rotator-pages li a {
        background-image: none;
        font-size: 20px;
        line-height: 10px;
        text-indent: 0;
      }

      .rotator-prev,
      .rotator-next {
        a {
          cursor: pointer;
        }
      }

      .rotator-pages {
        li {
          background-color: transparent;
        }

        .linkro-wocc a {
          opacity: 0.5;
        }
      }

      .ico {
        margin: 51px 8px;
      }

      .rotator-title {
        top: -24px;
        left: -38px;
        padding: 9px 3px 5px 9px;
        border-bottom: 2px solid white;
      }

      /* NARROW */
      .rotator {
        margin-left: 38px;
        margin-top: 24px;
        width: 100%;
      }

      .rotator-panels {
        li {
          height: 96px;
          margin-right: 2px;
          width: 130px;
        }

        li a > span {
          background-color: inherit;
          width: 116px;
        }

        .sponsoredText {
          display: block;
          color: #0ef;
          font-size: 12px;
          line-height: 1.1;
          text-transform: uppercase;
        }
      }

      .rotator-prev,
      .rotator-prev .bg,
      .rotator-prev a,
      .rotator-next,
      .rotator-next .bg,
      .rotator-next a {
        height: 136px;
        width: 36px;
      }
    }
  }

  /* WIDE */
  .articleWide #most-read-news.new {
    height: 176px;

    .ico {
      margin: 54px 9px;
    }

    .rotator-title {
      left: -41px;
    }

    .rotator {
      margin-left: 41px;
    }

    .rotator-panels {
      li {
        height: 102px;
        width: 137px;
      }

      li a > span {
        width: 123px;
      }
    }

    .rotator-prev,
    .rotator-prev .bg,
    .rotator-prev a,
    .rotator-next,
    .rotator-next .bg,
    .rotator-next a {
      height: 142px;
      width: 39px;
    }
  }

  /* EXTRA WIDE */
  .articleFullWidth #most-read-news.new {
    height: 182px;

    .ico {
      margin: 57px 10px;
    }

    .rotator-title {
      left: -42px;
    }

    .rotator {
      margin-left: 42px;
    }

    .rotator-panels {
      li {
        height: 108px;
        width: 145px;
      }

      li a > span {
        width: 131px;
      }
    }

    .rotator-prev,
    .rotator-prev .bg,
    .rotator-prev a,
    .rotator-next,
    .rotator-next .bg,
    .rotator-next a {
      height: 148px;
      width: 40px;
    }
  }
}
