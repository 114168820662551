@import '../layout';

.container {
  composes: contentContainer;
  flex: 1;
  margin: 0 auto;
  height: 100%;
  line-height: 0;

  > div {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}

:global(.mol-mobile) {
  .container {
    width: 150px;

    > div {
      margin-right: 0;
      margin-bottom: 5px;

      &:nth-child(odd) {
        margin-right: 5px;
      }
    }
  }

  .icon {
    width: 60px;
    height: 60px;
  }
}

.icon {
  composes: lightOverlay;
  display: inline-block;
  width: 80px;
  height: 80px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  cursor: pointer;

  > div {
    height: 100%;
    width: 100%;

    a {
      display: block;
      height: 100%;
      width: 100%;
    }
  }

  &:hover::after {
    visibility: visible;
  }
}

.facebook {
  composes: icon;
  background-color: #3c5a98;
  background-image: url('../../assets/facebook.svg');
}

.twitter {
  composes: icon;
  background-image: url('../../assets/twitter.svg');
}

.pinterest {
  composes: icon;
  background-color: #ea1514;
  background-image: url('../../assets/pinterest.svg');
}

.whatsapp {
  composes: icon;
  background-color: transparent;
  background-image: url('../../assets/whatsapp.svg');
}

.email {
  composes: icon;
  background-color: #451344;
  background-image: url('../../assets/email.svg');
}

.sms {
  composes: icon;
  background-color: transparent;
  background-image: url('../../assets/sms.svg');
}
