@import "../_settings/colors";
@import "../_settings/general";
$articleSprite: url($furnitureUrl + '/mobileimages/article-sprite_2.png');

#dms-txt {
  display: none;
  position: absolute;
  padding: 5px 5px 5px 0;
  z-index: 10;
  background-color: $bgBlack;
  background-color: $bgBlack85;
  width: 175px;
  height: 30px;

  li {
    float: left;
    margin: 0 0 5px 5px;
    background: $articleSprite no-repeat;
    cursor: pointer;
    width: 30px;
    height: 30px;
  }

  .share {
    font-size: 11px;
    background: none;
    text-align: left;
    padding-top: 3px;
    cursor: default;
    color: white;
    width: 65px;
    height: 28px;
  }

  .facebook {
    background-position: 0 -119px;

    &:hover {
      background-position: 0 -149px;
    }
  }

  .twitter {
    @include icon('twitter-outline');

    width: 30px;
    height: 30px;

    &:hover {
      opacity: 0.8;
    }
  }

  .mail {
    background-position: -270px -119px;

    &:hover {
      background-position: -270px -149px;
    }
  }
}
