$desktop-width: 480px;
$desktop-height-prompt: 214px;
$desktop-background: #fff;

$mobile-width: 314px;
$mobile-height: 285px;
$mobile-background: #fff;

:global {
  .safari-popup[data-state="closed"] {
    display: none;
  }

  .safari-popup-fade {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 1000ms, opacity 1000ms linear;
  }

  .safari-popup {
    .channel-showbiz-bg {
      background-color: #de2148;
    }

    .channel-default-bg {
      background-color: #16479c;
    }

    .channel {
      text-transform: capitalize;
    }

    .heading {
      margin-top: 20px;
      font-size: 20px;
      line-height: 27px;
    }

    .emphasis {
      font-weight: 600;
      font-size: 21px;
      text-transform: uppercase;
    }

    .privlink {
      float: left;
      font-size: 11px;
      color: #666;
    }

    .privlink a {
      text-decoration: underline;
      color: #666;
    }

    .safari-loading {
      display: none;
    }

    .safari-confirm {
      display: none;
      height: 100%;
    }

    width: $desktop-width;
    height: $desktop-height-prompt;
    background-color: $desktop-background;
    font: 15px var(--mol-default-font-family);
    top: 0;
    left: 50%;
    z-index: 99995;
    position: fixed;
    transform: translate(-50%, 0);
    box-shadow: 0 0 6px 4px rgba(0, 0, 0, 0.2);

    .safari-loading {
      display: none;
      background: url('./assets/desktop/spinner.svg') no-repeat 50% 50%;
      background-size: 30% 30%;
      height: 100%;
    }

    input[type="email"] {
      height: 40px;
      width: 98%;
      padding-left: 2%;
      color: #000;
      background-color: #f5f5f5;
      border: 1px solid #ccc;
    }

    input[type="submit"] {
      opacity: 0.85;
      float: right;
      font-weight: normal;
      letter-spacing: 0.1rem;
      color: #fff;
      width: 35%;
      height: 32px;
      border: none;
      cursor: pointer;
      -webkit-appearance: none;
      border-radius: 0;
    }

    input[type="email"]::placeholder {
      color: #666;
    }

    .error {
      font-size: 12px;
      color: #de2148;
      margin: 7px 0;
      visibility: hidden;
    }

    .content {
      width: 90%;
      margin: 0 auto;
      position: relative;
      padding: 0 18px;
    }

    .close-icon {
      cursor: pointer;
      position: absolute;
      top: 18px;
      right: 18px;
      background-image: url('./assets/desktop/close.svg');
      background-size: 14px 14px;
      background-repeat: no-repeat;
      height: 14px;
      width: 14px;
      z-index: 999999;
    }

    .mol-logo-container {
      width: 70px;
      height: 70px;
    }

    .mol-logo-default {
      background-color: #194fad;
    }

    .mol-logo-container > img {
      width: 47.2px;
      height: 35px;
      display: block;
      margin: 0 auto;
      padding-top: 17px;
    }

    .content > div {
      margin-bottom: 15px;
    }

    .safari-signup {
      display: flex;
      height: 100%;
    }
  }

  .mol-desktop .safari-popup {
    .c1 {
      max-width: 90px;
      min-width: 90px;
    }

    .c2 {
      margin: 0 15px 0 5px;
    }

    .left-banner-news {
      background-image: url(./assets/desktop/left-banner-news.svg);
    }

    .left-banner-sport {
      background-image: url(./assets/desktop/left-banner-sport.svg);
    }

    .left-banner-showbiz {
      background-image: url(./assets/desktop/left-banner-showbiz.svg);
    }

    .privlink a:hover {
      color: #808080;
    }

    .close-icon:hover {
      background-image: url('./assets/desktop/smartbanner-close.hover.svg');
    }

    input[type="submit"]:hover {
      background-image: none;
      opacity: 1;
    }

    .privlink {
      margin-top: 12px;
    }
  }

  .mol-mobile {
    .safari-popup.safari-popup-confirm-container {
      border-radius: 0;
    }

    .safari-popup {
      width: $mobile-width;
      height: $mobile-height;
      background-color: $mobile-background;
      font-family: var(--mol-default-font-family);
      text-align: center;
      top: 35%;
      left: 50%;
      transform: translate(-50%, 0);

      .c1 {
        width: 100%;
        height: 50px;
      }

      .c2 {
        position: absolute;
        top: 48px;
      }

      .heading {
        text-align: left;
        font-size: 20px;
        margin: 10px 0;
      }

      .emphasis {
        font-size: 22px;
      }

      .top-banner-sport {
        background-image: url(./assets/mobile/top-banner-sport.png);
      }

      .top-banner-news {
        background-image: url(./assets/mobile/top-banner-news.png);
      }

      .top-banner-showbiz {
        background-image: url(./assets/mobile/top-banner-showbiz.png);
      }

      input[type="email"] {
        height: 35px;
        margin-top: 10px;
        font-size: 14px;
        letter-spacing: 1.1px;
        width: 100%;
      }

      .safari-signup {
        padding: 0;

        form {
          display: block;
          width: 100%;

          input[type="submit"] {
            font-size: 14px;
            padding: 7px 15px;
            color: #fff;
            font-family: var(--mol-default-font-family);
            cursor: pointer;
            width: 105px;
            float: right;
            border: none;
          }
        }
      }

      .close-icon {
        position: absolute;
        background-image: url('./assets/mobile/close.icon.svg');
        z-index: 999999;
      }

      .content {
        width: 100%;
        margin: 20px auto;
        position: relative;
        text-align: left;
      }

      .spam {
        font-size: 14px;
      }

      .safari-confirm .content div:first-child {
        margin-top: 0;
      }

      .privlink {
        margin-top: 10px;
      }

      .error {
        font-size: 11px;
        float: left;
      }
    }
  }

  @media only screen and (orientation: landscape) {
    .mol-mobile .safari-popup {
      top: 5%;
    }
  }
}
