.article-text .sc-icns li {
  float: left;
  _display: inline;
}

.article-text .social-icons,
.article-text .social-icons li,
.article-text .social-icons ul {
  border: none !important;
  padding: 0 !important;
  margin-bottom: 0 !important;
}

.article-text .social-icons {
  width: 280px;
  margin-left: 4px;
}

.article-icon-links .twitter {
  width: 110px;
}

.article-icon-links li.fb {
  width: 90px;
}

.condensedLinks li {
  width: 30px;
}

.condensedLinks li a {
  color: #003580;
  width: 30px;
  height: 30px;
  display: block;
  position: relative;
  background: url("//i.dailymail.co.uk/i/mobileimages/article-sprite.png") no-repeat 0 0;
}

.condensedLinks li a {
  left: -2px;
}

.condensedLinks li a:hover {
  color: #003580;
}

.social-stats {
  background-position: -248px 0;
  margin-right: 0;
  width: auto !important;
  padding: 0 10px !important;
}

.social-stats-floating {
  background-position: -248px 0;
  margin-right: 0;
  width: auto !important;
  padding: 0 !important;
  min-width: 50px;
}

.social-stats-pipe-left {
  border-left: solid 1px #999;
}

.social-stats-pipe {
  border-left: solid 1px #999;
}

.social-stats-pipe-top {
  border-top: solid 1px #999;
  max-width: 40px;
  padding-top: 3px;
  margin-left: -2px;
}

.social-headline {
  display: none;
}
