@import "../../styles/variables";

$popupZIndex: 9999999;

.backDrop {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  background: rgba(#000, 0.7);
  z-index: $popupZIndex - 1;
}

.container {
  font-family: var(--mol-default-font-family);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: $popupZIndex;

  svg {
    width: 0.75 * $baseLine;
    height: 0.75 * $baseLine;
  }
}

.body {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
}

.closeButton {
  position: absolute;
  top: -0.75 * $baseLine;
  right: -0.75 * $baseLine;
  width: 26px;
  height: 26px;
  cursor: pointer;
  border-radius: 50%;
  background: #fff;
  z-index: 2;

  svg {
    height: 100%;

    g path {
      fill: #000;
    }
  }
}
