@import "../_settings/largeIcons";

#shareCountFloating {
  .shares_count {
    font-size: 3.4em;
    font-weight: bold;
  }

  .shares_count_small {
    font-size: 2.2em;
    font-weight: bold;
  }

  .shares_text,
  .shares_text_small {
    font-size: 1.2em;
  }
}

div.floating-menu {
  position: fixed;
  top: 10px;
  left: 146px;
  visibility: hidden;

  a,
  h3 {
    display: block;
  }
}

.floatingShareArticles {
  padding-right: 0 !important;
  width: 0 !important;

  ul li {
    height: 40px;
    width: auto !important;
    padding-right: 0;
    margin-bottom: 5px;
  }
}

.social-stats-floating {
  background-position: -248px 0;
  margin-right: 0;
  width: auto !important;
  padding: 0 !important;
  min-width: 50px;
}

#floatingShare {
  margin-left: -2px;

  a {
    height: 40px;
    width: 40px;
    background: $lgSpriteUrl no-repeat;
  }

  .facebook a {
    background-position: $lgFbPosition;

    &:hover {
      background-position: $lgFbHoverPosition;
    }
  }

  .twitter a {
    background-position: $lgTwPosition;

    &:hover {
      background-position: $lgTwHoverPosition;
    }
  }

  .pinterest a {
    background-position: $lgPinterestPosition;

    &:hover {
      background-position: $lgPinterestHoverPosition;
    }
  }

  .mail a {
    background-position: $lgMailPosition;

    &:hover {
      background-position: $lgMailHoverPosition;
    }
  }

  .share {
    p {
      border-top: solid 1px #999;
      width: 40px;
      padding-top: 3px;
      font-size: 3.4em;
      font-weight: bold;
    }

    &.small {
      p {
        font-size: 2.2em;
      }
    }
  }

  span {
    font-size: 1.2em;
  }
}

#socialLinksFloating .facebook a {
  background-position: $lgFbPosition;

  &:hover {
    background-position: $lgFbHoverPosition;
  }
}
