$baseLine: 16px;

$screenHeightSmall: 756px;
$screenMedium: 1200px;
$screenLarge: 1486px;

$fontSizeSmall: 0.875 * $baseLine;
$fontSize: $baseLine;
$fontSizeLarge: 1.125 * $baseLine;
$fontSizeExtraLarge: 1.25 * $baseLine;

$panelRightItemsPadding: 0.5 * $baseLine;

$recommendationsItemThumbHeight: 8 * $baseLine;
$recommendationsItemDescriptionHeight: 3.75 * $baseLine;
$recommendationsItemPadding: 0.25 * $baseLine;
