:global {
  .gamma-carousel {
    padding: 5px 16px 14px;
  }

  .femail .gamma-carousel {
    background: url("//i.dailymail.co.uk/i/furniture/galleries/carousel_femail_background.gif") no-repeat top right;
  }

  .gamma-carousel h3 {
    float: left;
    font-size: 1.17em;
    text-transform: uppercase;
  }

  .article-text .gamma-carousel h3 {
    background: transparent;
    padding: 0;
    color: #333;
  }

  .gamma-carousel {
    p {
      float: right;
      padding: 2px 0;
      font-weight: bold;
      font-size: 1em;
    }

    .carousel-holder {
      clear: both;
    }

    .scroller {
      width: 864px;
    }

    .itemlist {
      margin-left: -176px !important;

      li {
        margin-right: 16px !important;
        width: 160px;
        height: 140px;
      }

      a {
        height: 140px;
      }

      span {
        top: 110px;
        left: 0;
        width: 152px;
        height: 136px;
        color: #fff;
        font-size: 1.1em;
        font-family: var(--mol-default-font-family);

        strong {
          display: block;
        }
      }
    }

    .paging-controls a {
      width: 18px;
      height: 140px;
      top: -140px;
    }
  }

  /* carousel */
}
