.paginated {
  border: 1px solid #e3e3e3;
  border-bottom: none;
  display: flex;
  flex-direction: row;
}

.notPaginated {
  border: 1px solid #e3e3e3;
  border-bottom: none;
  display: flex;
  flex-direction: row;

  .tab,
  .tab.other {
    justify-content: center;
    flex-grow: 1;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.tab {
  width: 134px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  background-color: #f5f5f5;
  border-right: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  padding-left: 10px;
  padding-right: 4px;
  padding-top: 4px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: #e5e5e5;
  }

  &.selected {
    border-bottom: 1px solid #fff;
    background-color: #fff;
  }

  &.other {
    padding-left: 20px;

    &::before {
      display: none;
    }
  }

  &::before {
    content: "";
    display: block;
    width: 30px;
    height: 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 10px;
    flex-shrink: 0;
  }

  &:last-of-type {
    border-right: 0;
  }
}

.pageLeft,
.pageRight {
  width: 20px;
  height: 50px;
  background-color: #e0e0e0;
  color: #000;
  border: none;
  border-bottom: 1px solid #e3e3e3;
  cursor: pointer;
  padding: 0;
  appearance: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  outline: none;

  &[disabled] {
    background-color: #f5f5f5;
    color: #b6b6b6;
  }
}

.pageLeft {
  border-right: 1px solid #e3e3e3;

  &::before {
    display: block;
    content: "";
    width: 0;
    height: 0;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-right: 7px solid currentColor;
  }
}

.pageRight {
  border-left: 1px solid #e3e3e3;

  &::before {
    display: block;
    content: "";
    width: 0;
    height: 0;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-left: 7px solid currentColor;
  }
}

.scrollContainer {
  flex-grow: 1;
  overflow-x: hidden;
  position: relative;
}

.scroller {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: row;
}

.adaware::before {
  background-image: url(./assets/adaware.png);
}

.adBlock::before {
  background-image: url(./assets/adBlock.png);
}

.adBlockerUltimate::before {
  background-image: url(./assets/adBlockerUltimate.png);
}

.adBlockInc::before {
  background-image: url(./assets/adBlockInc.png);
}

.adBlockPlus::before {
  background-image: url(./assets/adBlockPlus.png);
}

.adBlockPro::before {
  background-image: url(./assets/adBlockPro.png);
}

.adGuard::before {
  background-image: url(./assets/adGuard.png);
}

.adsKiller::before {
  background-image: url(./assets/adsKiller.png);
}

.genesis::before {
  background-image: url(./assets/genesis.png);
}

.ghostery::before {
  background-image: url(./assets/ghostery.png);
}

.hola::before {
  background-image: url(./assets/hola.png);
}

.magicLasso::before {
  background-image: url(./assets/magicLasso.png);
}

.muBlock::before {
  background-image: url(./assets/muBlock.png);
}

.uBlocker::before {
  background-image: url(./assets/uBlocker.png);
}

.uBlockOrigin::before {
  background-image: url(./assets/uBlockOrigin.png);
}

.uBlockPlus::before {
  background-image: url(./assets/uBlockPlus.png);
}
