@import "general";

$lgSpriteUrl: url($furnitureUrl + '/furniture/articles/spt_socialicons_40x40eachbttn9.png');

$lgFbPosition: 0 -40px;
$lgFbHoverPosition: 0 0;

$lgFbLongPosition: -707px -40px;
$lgFbLongHoverPosition: -707px 0;

$lgTwPosition: -50px -40px;
$lgTwHoverPosition: -50px 0;

$lgTwLongPosition: -860px -40px;
$lgTwLongHoverPosition: -860px 0;

$lgPinterestPosition: -657px -40px;
$lgPinterestHoverPosition: -657px 0;

$lgRedditPosition: -300px -40px;
$lgRedditHoverPosition: -300px 0;

$lgFarkPosition: -400px -40px;
$lgFarkHoverPosition: -400px 0;

$lgLinkedinPosition: -500px -40px;
$lgLinkedinHoverPosition: -500px 0;

$lgPrintPosition: -550px -40px;
$lgPrintHoverPosition: -550px 0;

$lgMailPosition: -150px -40px;
$lgMailHoverPosition: -150px 0;

$lgArrowPosition: -600px 0;

$lgClosePosition: -630px 0;

@mixin icon($icon) {
  display: inline-block;
  vertical-align: top;
  width: 40px;
  height: 40px;
  background-size: contain;
  background-position: left;
  background-image: url('./assets/#{$icon}.svg');
  background-repeat: no-repeat;

  &:hover {
    opacity: 0.85;
  }
}
