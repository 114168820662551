:global {
  /*
    Rotator JavaScript component
  */
  .rotator {
    height: 192px;
    overflow: hidden;
    position: relative;
    width: 470px;

    .rotator-title {
      height: 20px;
      margin: 0;
      padding: 4px 0 0 4px;
      position: relative;

      h2 {
        font-size: 1.2em;
        margin: 0;
      }
    }

    .rotator-pages {
      margin: 0;
      padding: 0;
      position: absolute;
      right: 3px;
      top: 5px;

      li {
        background-color: #fff;
        display: block;
        float: left;
        height: 11px;
        list-style-type: none;
        margin-right: 5px;
        padding: 0;
        width: 11px;

        a {
          background-image: url(//i.dailymail.co.uk/i/furniture/spt_previewlinks_10.png);
          background-position: 0 -100px;
          display: block;
          height: 100%;
          text-indent: -9999em;
          width: 100%;
        }
      }

      .rotator-prev,
      .rotator-next {
        background-color: transparent;
        width: 7px;

        a {
          background-image: url(//i.dailymail.co.uk/i/furniture/spt_arrows_8.gif);
        }
      }

      .rotator-prev a {
        background-position: -20px -149px;
      }
    }

    &.home .rotator-pages .rotator-prev a:hover {
      background-position: -160px -149px;
    }

    &.dailymailtv .rotator-pages .rotator-prev a:hover {
      background-position: -160px -149px;
    }

    &.news .rotator-pages .rotator-prev a:hover {
      background-position: -200px -149px;
    }

    &.sport .rotator-pages .rotator-prev a:hover {
      background-position: -220px -149px;
    }

    &.tvshowbiz .rotator-pages .rotator-prev a:hover {
      background-position: -240px -149px;
    }

    &.femail .rotator-pages .rotator-prev a:hover {
      background-position: -260px -149px;
    }

    &.health .rotator-pages .rotator-prev a:hover {
      background-position: -280px -149px;
    }

    &.debate .rotator-pages .rotator-prev a:hover {
      background-position: -320px -149px;
    }

    &.sciencetech .rotator-pages .rotator-prev a:hover {
      background-position: -360px -149px;
    }

    &.money .rotator-pages .rotator-prev a:hover {
      background-position: -420px -149px;
    }

    &.travel .rotator-pages .rotator-prev a:hover {
      background-position: -440px -149px;
    }

    .rotator-pages .rotator-next a {
      background-position: -20px -119px;
    }

    &.home .rotator-pages .rotator-next a {
      &:focus,
      &:hover {
        background-position: -160px -119px;
      }
    }

    &.dailymailtv .rotator-pages .rotator-next a {
      &:focus,
      &:hover {
        background-position: -160px -119px;
      }
    }

    &.news .rotator-pages .rotator-next a {
      &:focus,
      &:hover {
        background-position: -200px -119px;
      }
    }

    &.sport .rotator-pages .rotator-next a {
      &:focus,
      &:hover {
        background-position: -220px -119px;
      }
    }

    &.tvshowbiz .rotator-pages .rotator-next a {
      &:focus,
      &:hover {
        background-position: -240px -119px;
      }
    }

    &.femail .rotator-pages .rotator-next a {
      &:focus,
      &:hover {
        background-position: -260px -119px;
      }
    }

    &.health .rotator-pages .rotator-next a {
      &:focus,
      &:hover {
        background-position: -280px -119px;
      }
    }

    &.debate .rotator-pages .rotator-next a {
      &:focus,
      &:hover {
        background-position: -320px -119px;
      }
    }

    &.sciencetech .rotator-pages .rotator-next a {
      &:focus,
      &:hover {
        background-position: -360px -119px;
      }
    }

    &.money .rotator-pages .rotator-next a {
      &:focus,
      &:hover {
        background-position: -420px -119px;
      }
    }

    &.travel .rotator-pages .rotator-next a {
      &:focus,
      &:hover {
        background-position: -440px -119px;
      }
    }

    .rotator-panels {
      left: 0;
      padding: 0;
      position: absolute;
      top: 24px;
      transition: transform 1.2s ease-out;
      width: 10000px;
    }
  }

  .js-itemList.rotator-panels {
    width: 20000px;
  }

  .rotator .rotator-panels li {
    display: block;
    float: left;
    height: 170px;
    list-style-type: none;
    margin-right: 4px;
    padding: 0;
    width: 154px;

    a {
      display: block;
      height: 100%;
      width: 100%;
    }

    img {
      display: block;
      width: 154px;
      height: 115px;
    }

    a {
      &:hover {
        text-decoration: none;
      }

      > span {
        display: block;
        font-size: 1.2em;
        font-weight: bold;
        height: 49px;
        padding: 4px 5px 0;
        width: 144px;
      }
    }
  }

  /*
    Wide and extrawide styles
  */
  .articleWide .rotator {
    width: 634px;

    .rotator-panels li {
      margin-right: 6px;
    }
  }

  .articleFullWidth .rotator {
    width: 964px;

    .rotator-panels li {
      margin-right: 8px;
    }
  }

  /* helpers for shallow styles */
  .rotator-pages .ico {
    background: url(//i.dailymail.co.uk/i/furniture/carousel_arrows_3.png) no-repeat 0 -99px;
  }

  .home,
  .dailymailtv {
    .rotator-prev .ico {
      background-position: 0 -66px;
    }
  }

  .news .rotator-prev .ico {
    background-position: 0 -99px;
  }

  .sport .rotator-prev .ico {
    background-position: 0 -132px;
  }

  .tvshowbiz .rotator-prev .ico {
    background-position: 0 -165px;
  }

  .femail .rotator-prev .ico {
    background-position: 0 -198px;
  }

  .health .rotator-prev .ico {
    background-position: 0 -231px;
  }

  .sciencetech .rotator-prev .ico {
    background-position: 0 -264px;
  }

  .travel .rotator-prev .ico {
    background-position: 0 -297px;
  }

  .money .rotator-prev .ico {
    background-position: 0 -330px;
  }

  .debate .rotator-prev .ico {
    background-position: 0 -363px;
  }

  .rotator-next .ico,
  .news .rotator-next .ico {
    background-position: -28px -99px;
  }

  .home,
  .dailymailtv {
    .rotator-next .ico {
      background-position: -28px -66px;
    }
  }

  .sport .rotator-next .ico {
    background-position: -28px -132px;
  }

  .tvshowbiz .rotator-next .ico {
    background-position: -28px -165px;
  }

  .femail .rotator-next .ico {
    background-position: -28px -198px;
  }

  .health .rotator-next .ico {
    background-position: -28px -231px;
  }

  .sciencetech .rotator-next .ico {
    background-position: -28px -264px;
  }

  .travel .rotator-next .ico {
    background-position: -28px -297px;
  }

  .money .rotator-next .ico {
    background-position: -28px -330px;
  }

  .debate .rotator-next .ico {
    background-position: -28px -363px;
  }

  .rotator-next.disabled .ico {
    background-position: -28px 0;
  }

  .rotator-prev.disabled .ico {
    background-position: 0 0;
  }

  /* reset for article and modules */
  .item,
  .article-text {
    .rotator ul {
      font-size: 0.85em;
    }
  }
}
