$social-side-margin: -8px;
$y-pos: -119px;
$y-pos-hover: -149px;
$hoverOpacity: 0.7;
$margin-vertical: 12px !default;
$border-width: 1px !default;

@mixin icon($icon) {
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  background-size: contain;
  background-position: center;
  background-image: url('./assets/#{$icon}.svg');
  background-repeat: no-repeat;
}

:global {
  .twpuffbutton {
    background-color: #000d;
    border-radius: 2px;
    background-image: url('./assets/twitterDark.svg') !important;
    background-position: unset !important;
    background-repeat: no-repeat;

    &:hover {
      background-color: #000b;
    }
  }

  .lc-social {
    .lc-twitter {
      background-image: url('./assets/twitterDark.svg') !important;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      &:hover {
        background-position: center;
        opacity: 0.8;
      }
    }
  }

  figure + .social {
    margin-top: 0;

    ul {
      border: 0;
    }
  }

  .social {
    margin: 0 $social-side-margin $margin-vertical;
    box-sizing: border-box;

    ul {
      padding: 8px;
      margin: 0;
      font-size: 13px;
      font-weight: 400;
      line-height: 14px;
      background-color: #fff;
      height: 48px;
      box-sizing: border-box;
    }

    li {
      float: left;
      margin: 0 4px 0 0;
      list-style-type: none;
    }

    a,
    label {
      @include icon('email');

      text-decoration: none;
      box-sizing: border-box;

      span {
        display: none;
      }
    }

    em {
      display: block;
      font-size: 24px;
      line-height: 18px;
      font-weight: bold;
    }

    .facebook a {
      @include icon('facebookLight');

      &.facebook-Dark {
        @include icon('facebookDark');
      }

      &.facebook-long {
        @include icon('facebookLongLight');

        width: 81px;
      }

      &.facebook-long-dark {
        @include icon('facebookLongDark');

        width: 81px;
      }

      @media screen and (min-width: 360px) {
        background-image: url(./assets/facebookLongMobile.svg);
        width: 70px;
      }
    }

    .twitter a {
      @include icon('share-twitter');

      &.twitter-dark {
        @include icon('share-twitter');
      }

      &.twitter-long-dark {
        @include icon('share-twitter-long');

        width: 86px;
      }
    }

    .share label {
      @include icon('share');
    }

    .pinterest a {
      @include icon('pinterest');
    }

    .whatsapp a {
      @include icon('whatsapp');
    }

    .reddit a {
      @include icon('reddit');
    }

    .fark a {
      @include icon('fark');
    }

    .linkedin a {
      @include icon('linkedin');
    }

    .email a {
      @include icon('emailDark');
    }

    .sms a {
      @include icon('sms');
    }

    .flipboard a {
      @include icon('flipboard');

      @media screen and (min-width: 360px) and (max-width: 374px) {
        display: none;
      }
    }

    .fbmessenger a {
      @include icon('facebook-messenger');
    }

    .native a {
      @include icon('native');

      background-size: 24px;
    }

    .native a:hover {
      background-color: #e0e0e0;
    }

    .close label {
      background-position: -300px $y-pos;
    }

    .native {
      display: block;
    }

    .share-count {
      float: right;

      b {
        font-size: 24px;
        line-height: 18px;
        padding-left: 7px;
        display: block;
        text-align: right;
      }

      span {
        height: 14px;
        font-size: 13px;
        font-weight: normal;
        color: #000;
        float: left;
        padding: 0 0 0 6px;
      }
    }

    .comments-count {
      margin: 0 (-$social-side-margin);
      background: var(--grey-color-gr1);
      font-size: 14px;
      font-weight: normal;
      line-height: 30px;
      text-align: center;

      a {
        color: var(--channel-color-home);
        width: auto;
        background: none;
      }

      em::before {
        @include icon('comments');

        content: "";
        margin: 0 5px 0 0;
        width: 18px;
        height: 18px;
      }

      em {
        font-size: 14px;
        display: inline;
      }
    }

    ul.more {
      position: absolute;
      padding: 4px;
      margin: 4px 0 0 -112px;
      border-width: 1px;
      border-color: transparent;
      background-color: transparent;
      width: (30px + 8px) * 8 + 8px + 2px;
      height: 0;
      overflow: hidden;
      transition: height 0.5s, border-color 0.5s, background-color 0.5s, transform 0.5s;

      li {
        margin: 4px;
      }
    }
  }

  div.mobile-sharing-tools {
    height: 47px;
    width: 100%;
    margin-bottom: 10px;

    .aboverelatedarticles {
      border-width: 1px 0;
      border-style: solid;
      border-color: #00aad2;
      padding: 7px;
      height: 100%;

      ul {
        border: 0;
        padding: 0;
        height: 35px;

        li.email {
          padding-left: 0;
        }

        @media screen and (max-width: 369px) {
          li.facebook {
            a.facebook-long-dark {
              @include icon('facebookDark');
            }
          }

          li.twitter {
            a.twitter-long-dark {
              @include icon('share-twitter');
            }
          }
        }
      }
    }
  }

  .new-mobile-article-design {
    figure + .social,
    .sponsored-by + .social,
    h1 + .social,
    h2 + .social {
      visibility: visible;
      display: flex !important;
      align-items: center;
      padding: 4px 0;

      ul {
        border: 0;
      }
    }

    .social:not(.video-lead):not(.bottom) {
      &.top {
        .email,
        .email a {
          display: none;
        }
      }

      ul {
        padding: 0 8px;
        height: 38px;

        @media screen and (max-width: 380px) {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
        }
      }

      li {
        &.email {
          order: 1;
        }

        &.copyToClipboard {
          order: 2;
        }

        &.native {
          order: 3;
        }
      }

      .facebook,
      .whatsapp,
      .fbmessenger,
      .native,
      .email,
      .copyToClipboard {
        border: 1px solid #ccc;
        border-radius: 50%;
        width: 38px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 380px) {
          width: 34px;
          height: 34px;
        }

        @media screen and (max-width: 360px) {
          width: 30px;
          height: 30px;
        }
      }

      .pinterest a {
        display: none;
      }

      .share-count {
        order: 4;
        margin-left: auto;

        b {
          color: #191919;
          font-family: var(--mol-default-font-family);
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 100%;
          text-align: center;
          padding: 0;
        }

        span {
          color: #191919;
          font-family: var(--mol-default-font-family);
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
        }
      }

      .flipboard,
      .flipboard a {
        display: none;
      }

      .twitter,
      .twitter a {
        display: none !important;
      }

      .whatsapp a {
        @include icon('whatsappSimple');

        width: 18px;
        height: 18px;
      }

      .facebook a {
        @include icon('facebookSimple');

        width: 18px;
        height: 18px;
      }

      .email a {
        @include icon('emailSimple');

        width: 18px;
        height: 18px;
      }

      .fbmessenger a {
        @include icon('facebookMessengerSimple');

        width: 18px;
        height: 18px;
      }

      .native a {
        width: 18px;
        height: 18px;
      }

      .copyToClipboard {
        display: flex !important;
      }

      .copyToClipboard a {
        display: inline-block !important;
        width: 18px;
        height: 18px;
      }

      .native {
        display: flex;
        background-color: #666;
      }

      &.home-preview {
        .comments-count {
          line-height: 30px;
          background: #f5f5f5;
        }
      }

      .comments-count {
        line-height: 0;
        background: white;
      }

      &.top {
        .share-count {
          display: none;
        }

        .comments-count {
          margin: 0 (-$social-side-margin) 0 auto;
          background: rgba(0, 0, 0, 0.08);
          font-size: 14px;
          font-weight: normal;
          line-height: 0;
          text-align: center;
          border: 1px solid rgba(0, 0, 0, 0);
          padding: 10px 15px;
          border-radius: 999px;
          align-self: center;
          align-items: center;
          height: 34px;
          display: flex;
          white-space: nowrap;

          a {
            color: var(--channel-color-home);
            width: auto;
            background: none;
            line-height: 0;
            height: auto;
          }

          em::before {
            @include icon('comments');

            content: "";
            margin: 0 4px 0 0;
            width: 14px;
            height: 14px;
          }

          em {
            font-size: 14px;
            display: inline;
          }
        }
      }
    }

    div.mobile-sharing-tools {
      display: none !important;
    }
  }
}

:global {
  @import './styles/index';
}
