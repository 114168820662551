:global {
  body.mol-mobile #comments {
    display: none;
    background: #f5f5f5;
    padding-bottom: 150px !important;

    .closeCommentsButton {
      position: absolute;
      border: none;
      appearance: none;
      cursor: pointer;
      padding: 0;
      outline: none;
      top: 8px;
      right: 10px;
      width: 35px;
      height: 35px;
      color: transparent;
      background: url(https://i.dailymail.co.uk/i/mobileimages/comments-sprite.png) no-repeat -45px 0;
    }

    > [data-spotim-module="conversation"] {
      margin-top: -25px;
      padding: 0 10px;

      &::after {
        position: absolute;
        top: 8px;
        right: 0;
        content: "";
        height: 35px;
        width: 55px;
        background: #fff;
      }
    }
  }

  body.comments-on.mol-mobile {
    .scrollable-content {
      padding-top: 0 !important;
    }

    #comments {
      display: block;
    }
  }

  body.comments-on.smart-banner.mol-mobile {
    #comments {
      padding-top: 80px;

      .closeCommentsButton {
        top: 88px;
      }
    }
  }

  .mol-desktop #reader-comments:not(.article-reader-comments),
  .mol-desktop #reader-comments-in-overlay:not(.article-reader-comments) {
    min-height: 370px !important;
    background-color: #f5f5f5;
    background-image: url(./assets/commentBubble.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 6.25rem 6.25rem;
  }

  .mol-fe-openweb-loaded .mol-desktop #reader-comments:not(.article-reader-comments),
  .mol-fe-openweb-loaded .mol-desktop #reader-comments-in-overlay:not(.article-reader-comments) {
    background-image: none;
  }

  .has-openweb-comments .mol-mobile #comments {
    background: #fff;
  }
}
