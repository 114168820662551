@import "../../styles/variables";

.container {
  width: 100%;

  &.disableNext {
    :global(.mol-skip-control) {
      cursor: default !important;

      &::before {
        color: #363636 !important;
      }
    }
  }

  &.disablePrev {
    :global(.mol-previous-control) {
      cursor: default !important;

      &::before {
        color: #363636 !important;
      }
    }
  }

  video {
    width: 100%;
  }

  :global(.vjs-title) {
    font-size: $fontSizeExtraLarge !important;
    height: 2.5 * $baseLine !important;
  }

  @media screen and (max-width: #{$screenMedium - 1px}) {
    :global(.vjs-title) {
      font-size: $fontSize !important;
    }
  }

  @media screen and (max-height: $screenHeightSmall) {
    :global(.vjs-title) {
      font-size: $fontSize !important;
      height: 3 * $baseLine !important;
    }
  }
}
