@import './variables';

:global {
  .mol-factbox[data-version="2"] {
    .mol-video {
      // So the video fits in the factbox. This can be removed when the video
      // player works with dynamic width
      margin-left: -1.5 * $baseLine;
    }
  }
}
