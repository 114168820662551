@import './variables';

:global {
  .mol-factbox[data-version="2"] {
    box-sizing: border-box;
    clear: both;
    margin-bottom: $baseLine;
    padding: $baseLine 1.5 * $baseLine 0;

    &.floatLHS,
    &.floatRHS {
      clear: none;
    }

    &.floatLHS,
    &.floatRHS,
    :global(.mol-mobile) & {
      padding: 0.75 * $baseLine $baseLine 0;
    }

    .mol-factbox-body {
      font-size: 16px;
      font-weight: 500;
      line-height: 23px;
      padding: 0;

      :global(.mol-desktop) & {
        color: #666;
      }

      p {
        margin-bottom: $baseLine;
      }

      ol {
        li {
          list-style-type: decimal;
        }
      }

      .imageCaption {
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
        margin-bottom: 0.75 * $baseLine;
        padding: 0.25 * $baseLine 0 0.5 * $baseLine;
        white-space: normal;
      }

      .artSplitter {
        .imageCaption {
          width: auto;
        }
      }

      :global(.mol-mobile) & {
        font-size: 15px;
        line-height: 21px;
      }

      :global(.cc-asset-content) & {
        padding-bottom: 3 * $baseLine;
      }
    }

    &.floatLHS,
    &.floatRHS {
      .mol-factbox-body {
        font-size: 15px;
        line-height: 21px;

        p {
          margin-bottom: 0.75 * $baseLine;
        }
      }

      .imageCaption {
        font-size: 13px;
        line-height: 18px;
      }
    }

    .mol-factbox-title {
      font-size: 34px;
      line-height: 38px;
      font-weight: bold;
      margin-bottom: 0.75 * $baseLine;
      padding: 0 0 $baseLine;
    }

    &.floatLHS,
    &.floatRHS,
    :global(.mol-mobile) & {
      .mol-factbox-title {
        font-size: 24px;
        line-height: 28px;
        padding: 0 0 0.75 * $baseLine;
      }
    }

    // We need to make black the bold text but honour the colour set on CC if any
    // Level 4 selectors are only supported on Safari, so we can't pass a list of selectors
    // to the negation pseudo-class :not. This would work on Safari
    // .mol-desktop & .mol-style-bold:not([class*="ccox"], [class*="ccox"] .mol-style-bold)
    // But in any other browser you can't do this, see https://www.w3.org/TR/css3-selectors/#negation
    // about Level 3 CSS selectors
    :global(.mol-desktop) & .mol-factbox-body [class*="ccox"] .mol-style-bold {
      color: inherit;
    }

    :global(.mol-desktop) & .mol-style-bold:not([class*="ccox"]) {
      color: #000;
    }

    :global(.mol-mobile) & {
      margin: 0 -0.5 * $baseLine 0.75 * $baseLine;

      p {
        margin-bottom: 0.75 * $baseLine;
      }

      figcaption {
        font-size: 13px;
        font-weight: bold;
        line-height: 18px;
        padding: 0.25 * $baseLine 0;
        padding-right: 52px;
      }
    }

    :global(.cc-asset-content) & {
      position: relative;

      &::after {
        bottom: 0.75 * $baseLine;
        content: url(./assets/social_black.png);
        position: absolute;
        right: 1.5 * $baseLine;
      }
    }
  }

  // Hack to accomodate 50/50 factboxes on wide articles
  .alpha .mol-factbox[data-version="2"].floatLHS + .mol-factbox[data-version="2"].floatLHS {
    margin-left: 4px;
    margin-right: 0;
  }
}
