@import "_settings/general";

$socialIcons: (
  facebook: ('facebookLight', #34447b, #3a4d8c),
  flipboard: ('flipboard', #a10000, #c00),
  twitter: ('twitterDark', #262626, #000c),
  pinterest: ('pinterest', #a00e16, #b50f1b),
  instagram: ('instagram', #ab185d, #c11d69),
  snapchat: ('snapchat', #e3e800, #f0f500),
  youtube: ('youtube', #c30000, #ce0000),
  tiktok: ('tiktok', #000, #000),
  threads: ('threads', #000, #000)
);

$iconSize: 40px;

.dms-puff {
  li {
    height: $iconSize;
    width: 152px;
    padding: 0;
    margin-bottom: 3px;
    display: inline-block;
  }

  a {
    position: relative;
    box-sizing: border-box;
    height: $iconSize;
    display: block;
    padding: 6px 0 0 calc(#{$iconSize} + 6px);
    color: white;
    border-radius: 3px;

    &::before {
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      height: $iconSize;
      width: $iconSize;
      background-size: 100%;
    }
  }

  span {
    display: block;
  }

  @each $social, $values in $socialIcons {
    .#{$social} a {
      background-color: nth($values, 2);

      &::before {
        background-image: url('./assets/#{nth($values, 1)}.svg');
      }

      &:hover {
        background-color: nth($values, 3);
      }
    }
  }

  .snapchat a {
    color: black;
  }

  .youtube a {
    &::before {
      background-color: #e50000;
      background-size: 23px 24px;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}
