@import "../../styles/variables";

.container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  text-align: left;
  padding: 0.625 * $baseLine $baseLine $baseLine;
  color: #fff;
  margin: 0;
}

.headline {
  font-size: 1.375 * $fontSize;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 0.5 * $baseLine;
  cursor: pointer;

  a {
    display: block;
    color: #fff !important;

    &:hover {
      text-decoration: none;
    }
  }

  &:hover a {
    color: rgba(255, 255, 255, 0.7) !important;
  }
}

.previewText {
  font-size: $fontSizeSmall;
  line-height: 1.3;
  font-weight: 300;
  display: block;
}

.socialShare {
  box-sizing: border-box;
  margin-top: 1.25 * $baseLine;
  font-size: 0.8125 * $baseLine;

  :global(ul.article-icon-links li a) {
    color: #fff !important;

    :global(.icon) {
      background-position: 0 0 !important;
      background-repeat: no-repeat !important;
    }

    &:global(.comments-link .icon) {
      background-image: url(./assets/comments.svg) !important;
    }

    &:global(.videos-link .icon) {
      background-image: url(./assets/videos.svg) !important;
    }

    &:global(.share-link .icon) {
      background-image: url(./assets/shares.svg) !important;
    }
  }
}

@media screen and (min-width: $screenMedium) {
  .headline {
    font-size: 1.4 * $fontSize;
  }

  .previewText {
    font-size: $fontSize;
    line-height: 1.25;
  }
}

@media screen and (min-width: $screenLarge) {
  .headline {
    font-size: 2 * $fontSize;
  }

  .previewText {
    font-size: $fontSizeLarge;
    line-height: 1.25;
  }
}

@media screen and (max-height: $screenHeightSmall) {
  .headline {
    font-size: $baseLine;
  }

  .previewText {
    display: none;
  }
}
