.container {
  margin-bottom: 40px;
}

.heading {
  padding-left: 30px;
  background: url(./assets/camera.png) left center no-repeat;
  background-size: 20px 15px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  line-height: 15px;
  text-transform: uppercase;
}

.items {
  width: 100%;
}

.item {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.itemImage {
  width: 134px;
  height: 100px;
  background-size: cover;
  background-position: top center;
  margin-right: 10px;
  flex-shrink: 0;
}

.headline {
  font-size: 16px;
  line-height: 1.15;
  color: #fff;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  max-height: 90px;
  overflow: hidden;
}
