.container {
  width: 100%;

  &.loading {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.galleryNextWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.horizontal {
  .viewNextWrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 10px;
    margin-top: 10px;
    cursor: pointer;

    &:hover {
      .viewNextButton {
        background-color: #105dc3;
      }

      .viewNextPreview {
        background: #10468d;
      }
    }
  }

  .galleryNextWrapper.noSecondResults {
    .viewNextWrapper {
      margin-left: 0;
      max-width: 50%;
      height: 220px;
    }
  }

  .viewNextPreview {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    padding: 10px;
    box-sizing: border-box;
    background: #00367d;
  }

  .viewNextThumb {
    width: 42%;
    flex-shrink: 0;
    margin-right: 10px;
    overflow: hidden;
  }

  .viewNextHeadline {
    flex-grow: 1;
    font-size: 14px;
    color: #fff;
    line-height: 1.15;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 94px;
  }

  .viewNextButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
    box-sizing: border-box;
    color: #fff;
    background-color: #004db3;
    background-image: url(./assets/chevron.svg);
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 17px 34px;
    padding: 10px;
    font-size: 24px;
    font-weight: 600;
    height: 41%;
  }

  @media screen and (min-width: 1800px) {
    .viewNextWrapper {
      margin-top: 20px;
      margin-left: 20px;
    }

    .viewNextHeadline {
      font-size: 18px;
      line-height: 1.25;
    }

    .viewNextPreview {
      padding: 20px;
    }

    .viewNextThumb {
      margin-right: 20px;
    }

    .viewNextButton {
      padding: 20px;
      background-size: 21px 42px;
      background-size: right 24px center;
      font-size: 28px;
    }
  }
}

.smallHorizontal {
  .viewNextButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    color: #fff;
    background-color: #004db3;
    background-image: url(./assets/chevron.svg);
    background-repeat: no-repeat;
    background-position: right 18px center;
    background-size: 11px 22px;
    font-size: 20px;
    font-weight: 600;
    height: 55px;
    margin-top: 20px;

    @media (orientation: landscape) {
      width: 50%;
      margin: auto;
      height: 45px;
      margin-top: 10px;
    }
  }
}

.vertical {
  .viewNextWrapper {
    width: 100%;
    margin-top: 20px;
    cursor: pointer;

    &:hover {
      .viewNextButton {
        background-color: #105dc3;
      }

      .viewNextPreview {
        background: #10468d;
      }
    }
  }

  .viewNextPreview {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    background: #00367d;
  }

  .viewNextThumb {
    width: 134px;
    flex-shrink: 0;
  }

  .viewNextHeadline {
    flex-grow: 1;
    font-size: 16px;
    color: #fff;
    line-height: 1.15;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding: 5px 10px;
    box-sizing: border-box;
    max-height: 95px;
  }

  .viewNextButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    color: #fff;
    background-color: #004db3;
    background-image: url(./assets/down.svg);
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 24px 14px;
    font-size: 20px;
    font-weight: 600;
    height: 55px;
  }
}
