@import "../_settings/largeIcons";

#content .shareArticles {
  float: left;
  width: 232px;
  border-top: none;
  border-bottom: none;
  height: auto;
  margin: 0;
  padding: 0 10px 0 0;

  ul {
    width: 100%;
  }

  ul li {
    padding-right: 2px;

    &:last-child {
      padding-right: 0;
    }
  }

  .condensedLinks {
    .share-icons a {
      background-position: -60px -119px;

      &:hover {
        background-position: -60px -149px;
      }
    }

    .share-twitter a {
      background-position: -30px -119px;

      &:hover {
        background-position: -30px -149px;
      }
    }

    .share-facebook a {
      background-position: 0 -119px;

      &:hover {
        background-position: 0 -149px;
      }
    }
  }
}

#content .narrow .shareArticles {
  position: relative;
  width: 469px;
}

#content .wide .shareArticles {
  width: 634px;
}

#content .articleFullWidth .shareArticles {
  width: 100%;

  #socialLinks {
    position: relative;
  }
}

#content h3.social-links-title {
  display: block;
  border-bottom: 1px solid #e3e3e3;
  font-size: 1.6em;
  font-weight: bold;
  margin: 0;
  padding: 0;
  color: #000;
  background-color: transparent;
}

#content .column-content .social-links {
  padding: 10px 0 0;
}

#shareCountBottom {
  height: 40px;
  margin-right: 0;
  background-image: inherit;

  .shares_text {
    margin-top: -6px;
  }

  .shares_text_small {
    margin-top: 5px;
  }

  .shares_count {
    font-size: 2.8em;
    font-weight: bold;
    margin-top: -5px;
  }

  .shares_count_small {
    font-size: 2em;
    font-weight: bold;
    margin-top: -4px;
  }
}

#addCommentNarrowButton,
#addCommentButton {
  float: right;
  margin-right: 0;
  padding-left: 10px;
  background-image: inherit;

  &,
  .add-comment__button {
    width: auto;
    height: auto;
  }

  .add-comment__button {
    display: inline-block;
    background: #004db3;
    border-radius: 2px;
    padding: 12px;
    font-size: 16px;
    color: white;
  }
}

#addCommentNarrowButton {
  .add-comment__button {
    width: 90px;
    padding: 6px;
    font-size: 14px;
    text-align: center;
  }
}
