:global {
  .video_carousel_container {
    border-bottom: 0;
    border-right: 0;
    border-left: 0;
  }

  .video_carousel_title {
    height: 30px;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
    margin-bottom: 7px;
    border-width: 1px 0 0 !important;

    div {
      float: left;
      height: 19px;
      padding: 5px;
    }

    img {
      position: absolute;
      left: 6px;
      top: -2px;
    }

    .carousel-title {
      padding-right: 15px;
      text-indent: 42px;
    }

    .channel-title {
      font-weight: bold;
      margin: 5px 7px;
      padding: 0;
    }

    .rotator-title {
      ul {
        position: absolute;
        right: 3px;
        top: 10px;
      }

      li {
        float: left;
        padding: 0;
        list-style: none;
      }
    }

    .rotator-pages {
      a {
        background-image: url(//i.dailymail.co.uk/i/furniture/spt_previewlinks_10.png);
        background-position: -30px -100px;
        float: left;
        display: block;
        opacity: 0.5;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
        filter: alpha(opacity = 50);
        width: 11px;
        height: 7px;
        padding-top: 4px;
        margin-right: 5px;
        text-indent: 9999em;

        &:hover {
          opacity: 1 !important;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)" !important;
          filter: alpha(opacity = 100) !important;
        }
      }

      .selected {
        opacity: 1 !important;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)" !important;
        filter: alpha(opacity = 100) !important;
      }
    }
  }

  .video_carousel_container {
    .left_scroll {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1000;
      height: 163px;
      width: 43px;
      background: #e0e0e0;
      opacity: 0.8;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
      filter: alpha(opacity = 80);
    }

    .right_scroll {
      position: absolute;
      top: 0;
      z-index: 1000;
      height: 163px;
      width: 43px;
      background: #e0e0e0;
      opacity: 0.8;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
      filter: alpha(opacity = 80);
      left: auto;
      right: 0;
    }

    .left_scroll span {
      position: absolute;
      right: 10px;
      top: 60px;
      width: 20px;
      height: 33px;
      background: url(//i.dailymail.co.uk/i/furniture/carousel_arrows_3.png) no-repeat 0 0;
    }

    .right_scroll span {
      position: absolute;
      right: 10px;
      top: 60px;
      width: 20px;
      height: 33px;
      background: url(//i.dailymail.co.uk/i/furniture/carousel_arrows_3.png) no-repeat 0 0;
      background: url(//i.dailymail.co.uk/i/furniture/carousel_arrows_3.png) no-repeat -28px 0;
    }

    .blank-div {
      position: absolute;
      width: 43px;
      bottom: 0;
      left: 0;
      height: 114px;
      background: white;
      z-index: 1000;
    }

    .right .blank-div {
      left: auto;
      right: 0;
    }

    .rotator {
      position: absolute;
      top: 0;
      height: 348px;
      left: 45px;
      width: 877px;
      overflow: visible;

      ul {
        top: 0;
      }

      .rotator-panels li {
        position: relative;
        list-style: none;
        overflow: hidden;
        height: 277px;
        float: left;
        width: 290px;
        margin-right: 2px;

        img {
          width: 100%;
          height: 55%;
        }

        a span {
          position: absolute;
          width: 120px;
          right: -90px;
          opacity: 0.85;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
          filter: alpha(opacity = 85);
          height: 38px;
          font-size: 13px;
        }
      }
    }

    .playbtn {
      border-style: dashed;
      border-color: transparent;
      border-width: 0.53em;
      display: -moz-inline-box;
      display: inline-block;
      font-size: 19px;
      height: 0;
      line-height: 0;
      vertical-align: middle;
      width: 0;
      margin: 7px 0 7px 7px;
      text-align: left;
      border-left: 1em solid white;
      zoom: 1;
    }

    .rotator {
      .video-item-time {
        display: none;
      }

      p {
        position: absolute;
        left: 5px;
      }
    }

    .video-item-title {
      padding: 6px 9px;
      border-width: 1px 0 0 !important;
      font-weight: bold;
      font-size: 22px;
      line-height: 26px;
    }
  }

  .home.video_carousel_container a:hover .left_scroll span,
  .video.video_carousel_container a:hover .left_scroll span {
    background-position: 0 -66px;
  }

  .news.video_carousel_container a:hover .left_scroll span {
    background-position: 0 -99px;
  }

  .sport.video_carousel_container a:hover .left_scroll span {
    background-position: 0 -132px;
  }

  .tvshowbiz.video_carousel_container a:hover .left_scroll span {
    background-position: 0 -165px;
  }

  .sciencetech.video_carousel_container a:hover .left_scroll span {
    background-position: 0 -264px;
  }

  .femail.video_carousel_container a:hover .left_scroll span {
    background-position: 0 -198px;
  }

  .health.video_carousel_container a:hover .left_scroll span {
    background-position: 0 -231px;
  }

  .money.video_carousel_container a:hover .left_scroll span {
    background-position: 0 -330px;
  }

  .travel.video_carousel_container a:hover .left_scroll span {
    background-position: 0 -298px;
  }

  .home.video_carousel_container a:hover .right_scroll span,
  .video.video_carousel_container a:hover .right_scroll span {
    background-position: -28px -66px;
  }

  .news.video_carousel_container a:hover .right_scroll span {
    background-position: -28px -99px;
  }

  .sport.video_carousel_container a:hover .right_scroll span {
    background-position: -28px -132px;
  }

  .tvshowbiz.video_carousel_container a:hover .right_scroll span {
    background-position: -28px -165px;
  }

  .sciencetech.video_carousel_container a:hover .right_scroll span {
    background-position: -28px -264px;
  }

  .femail.video_carousel_container a:hover .right_scroll span {
    background-position: -28px -198px;
  }

  .health.video_carousel_container a:hover .right_scroll span {
    background-position: -28px -231px;
  }

  .money.video_carousel_container a:hover .right_scroll span {
    background-position: -28px -330px;
  }

  .travel.video_carousel_container a:hover .right_scroll span {
    background-position: -28px -298px;
  }

  .home {
    .womlcc,
    .link-womlcc a,
    .linkro-womlcc a:hover {
      color: #fff;
      background-color: #1159bf;
    }
  }

  .video {
    .womlcc,
    .link-womlcc a,
    .linkro-womlcc a:hover {
      color: #fff;
      background-color: #1159bf;
    }
  }

  .news {
    .womlcc,
    .link-womlcc a,
    .linkro-womlcc a:hover {
      color: #fff;
      background-color: #0088a8;
    }
  }

  .sport {
    .womlcc,
    .link-womlcc a,
    .linkro-womlcc a:hover {
      color: #fff;
      background-color: #087808;
    }
  }

  .tvshowbiz {
    .womlcc,
    .link-womlcc a,
    .linkro-womlcc a:hover {
      color: #fff;
      background-color: #9c142f;
    }
  }

  .femail {
    .womlcc,
    .link-womlcc a,
    .linkro-womlcc a:hover {
      color: #fff;
      background-color: #8a4473;
    }
  }

  .health {
    .womlcc,
    .link-womlcc a,
    .linkro-womlcc a:hover {
      color: #fff;
      background-color: #248e8e;
    }
  }

  .travel {
    .womlcc,
    .link-womlcc a,
    .linkro-womlcc a:hover {
      color: #fff;
      background-color: #153e69;
    }
  }

  .sciencetech {
    .womlcc,
    .link-womlcc a,
    .linkro-womlcc a:hover {
      color: #fff;
      background-color: #b24700;
    }
  }

  .money {
    .womlcc,
    .link-womlcc a,
    .linkro-womlcc a:hover {
      color: #fff;
      background-color: #300d2f;
    }
  }

  #video_mosaic,
  .video-carousel-body {
    position: relative;
    height: 277px;
    width: 100%;
    border-width: 0;
    overflow: hidden;
  }

  #video_mosaic div,
  .video-carousel-body div {
    min-height: 0;
  }
}
