// @TODO USE baseline 16px
@import "../../styles/variables";

@mixin setSizes($config) {
  $width: map-get($config, width);
  $height: map-get($config, height);
  $panelRightWidth: map-get($config, panelRightWidth);
  $panelRightHeadFont: map-get($config, panelRightHeadFont);
  $panelRightFootFont: map-get($config, panelRightFootFont);
  $panelRightHeadHeight: map-get($config, panelRightHeadHeight);
  $panelRightFootHeight: map-get($config, panelRightFootHeight);

  .container {
    width: $width;
    min-height: $height;
  }

  .panelRight {
    padding-top: $panelRightHeadHeight;
    padding-bottom: $panelRightFootHeight;
    width: $panelRightWidth;
  }

  .panelLeft {
    width: $width - $panelRightWidth;
  }

  .panelRightFoot {
    height: $panelRightFootHeight;
    line-height: $panelRightFootHeight;
    font-size: $panelRightFootFont;
  }

  .panelRightHead {
    height: $panelRightHeadHeight;
    line-height: $panelRightHeadHeight;
    font-size: $panelRightHeadFont;
    text-transform: uppercase;
  }
}

.container {
  position: relative;
  background: #000;
  box-sizing: border-box;
  padding: 0;
  margin: 0 auto;
  text-align: left;

  * {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
  }
}

.panelLeft {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.panelRight {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  overflow: hidden;
  background: #242424;
}

.panelRightHead {
  padding-left: $panelRightItemsPadding;
  padding-right: $panelRightItemsPadding;
  position: absolute;
  top: 0;
  width: 100%;
  color: #fff;
  line-height: 1.5;
  font-weight: 500;
}

.panelRightFoot {
  padding-left: $panelRightItemsPadding;
  padding-right: $panelRightItemsPadding;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: right;
  font-weight: 400;
  line-height: 1.5;

  a {
    color: #fff;
  }
}

.video {
  display: block;
  width: 100%;

  video {
    background-image: url('./assets/spinner.gif');
    background-repeat: no-repeat;
    background-position: 50%;
  }
}

.recommendations {
  display: block;
  width: 100%;
  height: 100%;
  font-size: $fontSize;
  overflow-x: hidden;
  overflow-y: scroll;
  overflow-y: -moz-scrollbars-vertical;
  scrollbar-face-color: #999;
  scrollbar-shadow-color: #5d5d5d;
  scrollbar-highlight-color: #5d5d5d;
  scrollbar-darkshadow-color: #5d5d5d;
  scrollbar-track-color: #5d5d5d;
  scrollbar-arrow-color: #000;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0.5 * $baseLine;
  }

  &::-webkit-scrollbar-track {
    background: #5d5d5d;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #999;
  }
}

.articleText {
  display: block;
}

@include setSizes((
  width: 55 * $baseLine,
  height: 40 * $baseLine,
  panelRightWidth: 12 * $baseLine,
  panelRightHeadFont: $fontSize,
  panelRightFootFont: $fontSizeSmall,
  panelRightHeadHeight: 2.5 * $baseLine,
  panelRightFootHeight: 2.5 * $baseLine
));

@media screen and (min-width: $screenMedium) {
  @include setSizes((
    width: 71 * $baseLine,
    height: 40 * $baseLine,
    panelRightWidth: 24 * $baseLine,
    panelRightHeadFont: $fontSizeExtraLarge,
    panelRightFootFont: $fontSize,
    panelRightHeadHeight: 2.5 * $baseLine,
    panelRightFootHeight: 4 * $baseLine
  ));
}

@media screen and (min-width: $screenLarge) {
  @include setSizes((
    width: 90 * $baseLine,
    height: 40 * $baseLine,
    panelRightWidth: 25 * $baseLine,
    panelRightHeadFont: $fontSizeExtraLarge,
    panelRightFootFont: $fontSize,
    panelRightHeadHeight: 2.5 * $baseLine,
    panelRightFootHeight: 4 * $baseLine
  ));
}

@media screen and (max-height: $screenHeightSmall) {
  @include setSizes((
    width: 50 * $baseLine,
    height: 25 * $baseLine,
    panelRightWidth: 24 * $baseLine,
    panelRightHeadFont: $baseLine,
    panelRightFootFont: $baseLine,
    panelRightHeadHeight: 3 * $baseLine,
    panelRightFootHeight: 3 * $baseLine
  ));
}
