@import "_settings/largeIcons";
@import "_settings/general";
$imgIconsUrl: url($furnitureUrl + '/furniture/articles/sprite_image_sharing_2.png');

body.mol-desktop {
  .overlay-icon.share-image {
    bottom: 65px;
    width: 100px;
  }

  .share-pictures-links {
    bottom: 59px;

    &.image-share-horizontal {
      bottom: 10px;
    }
  }
}

#share-pictures {
  display: none;
}

.share-pictures-links.overlay-icon {
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  right: 10px;
  line-height: 25px;
  height: 280px;
  padding: 13px;
}

.share-pictures-links {
  &.image-share-visible {
    display: block;
  }

  &.image-share-horizontal {
    right: 10px;
    height: 30px;

    .image-share-url-lightbox {
      left: 93px;
      bottom: -73px;
    }

    ul {
      width: 240px;
    }

    li {
      width: 30px;
      height: 30px;
      display: inline-block;

      &.text {
        width: 44px;
        position: relative;
        bottom: 13px;
        margin-left: 10px;
      }

      &.facebook {
        background-position: 0 -120px;

        &:hover {
          background-position: 0 -90px;
        }
      }

      &.twitter {
        background-position: -40px -120px;

        &:hover {
          background-position: -40px -90px;
        }
      }

      &.pinterest {
        background-position: -160px -120px;

        &:hover {
          background-position: -160px -90px;
        }
      }

      &.email {
        background-position: -120px -120px;

        &:hover {
          background-position: -120px -90px;
        }
      }
    }
  }

  ul {
    margin-bottom: 0;
    padding: 0;
  }

  .share-pictures-icon {
    width: 40px;
    height: 40px;
    padding: 0;
    margin: 0 0 10px;
    list-style: none;

    &.text {
      background: none;
      font-size: 11px;
      line-height: 14px;
      height: 30px;
      width: 60px;
      position: relative;
      left: -10px;
    }

    &.facebook {
      background-position: 0 -40px;

      &:hover {
        background-position: 0 0;
      }
    }

    &.twitter {
      background-position: -51px -40px;

      &:hover {
        background-position: -51px 0;
      }
    }

    &.pinterest {
      background-position: -340px -40px;

      &:hover {
        background-position: -340px 0;
      }
    }

    &.email {
      background-position: -153px -40px;

      &:hover {
        background-position: -153px 0;
      }
    }
  }

  li {
    background-image: $imgIconsUrl;
  }
}

.image-wrap:hover .share-pictures-links {
  &.image-share-horizontal {
    &.image-share-hide {
      display: none;
    }
  }
}

.share-pictures-arrow {
  background: $imgIconsUrl no-repeat -311px -40px;
  width: 19px;
  height: 10px;
  position: absolute;
  bottom: -10px;
  right: 40px;
}

.share-image {
  right: 10px;
  text-transform: uppercase;
  display: table;
  box-sizing: border-box;
  height: 36px;
  line-height: 14px;
  font-size: 10px;

  div {
    text-align: left;
  }
}

.share-image-icon {
  background: $imgIconsUrl no-repeat -210px -43px;
  width: 25px;
  height: 35px;
  float: left;
  margin-left: 10px;
}

.share-image-text {
  float: none;
  display: table-cell;
  vertical-align: middle;
}

.image-share-url-lightbox {
  left: -109px;
  display: none;
  position: absolute;
  font-size: 13px;
  background-color: white;
  z-index: 99998;
  border: 1px solid #c0c0c0;
  padding: 10px 10px 0;
}

#share-url-lightbox-content {
  height: 54px;
  width: 250px;
  padding: 5px;
  text-align: right;

  #share-url-input {
    border: 1px solid #c0c0c0;
    padding: 3px 3px 3px 20px;
    width: 90%;
    background: url($furnitureUrl + '/furniture/comments/articles/icon_share_22.png') no-repeat -100px -40px;
  }

  p {
    color: black;
    margin-top: 5px;
    font-size: 12px;
  }
}

#image-share-url-lightbox-arrow {
  left: 131px;
  top: -11px;
  position: absolute;
  z-index: 99999;
  width: 20px;
  height: 11px;
  background: $lgSpriteUrl -600px 0;
}
