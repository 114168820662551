@import './variables';

:global(body.mol-desktop) {
  :global(.mol-factbox[data-version="2"]) {
    .socialContainer {
      padding: $baseLine 0;
      text-align: right;
    }

    .socialButton {
      display: inline-block;
      cursor: pointer;
      height: $baseLine;
      margin-left: $baseLine / 2;
      margin-right: $baseLine / 2;
      position: relative;
      text-align: center;
      width: 1.5 * $baseLine;

      svg {
        height: 100%;
        width: auto;
      }
    }

    &:global(.wellness) {
      .socialButton {
        > svg * {
          fill: #b5751a;
        }
      }
    }
  }
}

:global(body.mol-mobile) {
  :global(.mol-factbox[data-version="2"]) {
    .socialContainer {
      padding: $baseLine 0;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
    }

    .socialButton {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      height: 38px;
      width: 38px;
      border-radius: 999px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding: 10px;

      &:global(.native-share) {
        background-color: #666;
      }

      svg {
        height: 18px;
        width: 18px;
      }
    }
  }
}

:global(body.mol-mobile) .tooltipContainer {
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1001;
}
