.highlightedComment {
  border-left: 7px solid red;
  outline: none;
}

.articleComment {
  display: flex;
  flex-flow: column nowrap;
  background-color: #f5f5f5;

  .articleContentRow {
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    width: 100%;
    min-height: 110px;
    box-sizing: border-box;
    border-top: 1px solid silver;

    &:hover {
      .reportAbuseIcon {
        width: 11px;
        height: 11px;
        background: url('https://i.dailymail.co.uk/i/furniture/comments/articles/spt_arrows_commentrating.png') no-repeat -490px 0;

        &:hover {
          background: url('https://i.dailymail.co.uk/i/furniture/comments/articles/spt_arrows_commentrating.png') no-repeat -490px -59px;
        }
      }
    }

    .avatar {
      min-width: 55px;
      height: 66px;

      img {
        width: 55px;
        position: absolute;
        clip: rect(0 55px 55px 0);
      }
    }

    .content {
      width: 100%;
      padding: 10px 0 0 15px;
    }

    .reportAbuseLink {
      position: absolute;
      top: 5px;
      right: 5px;
    }

    .commentBody {
      padding-right: 10px;

      p {
        font-size: 13px;
        padding-bottom: 10px;
        margin-bottom: 7px;
        word-wrap: break-word;
        word-break: break-word;
      }

      .info {
        font-size: 12px;
        color: #999;

        a {
          color: #003580;
          font-size: 12px;
        }
      }

      .title {
        color: #004db3;
        font-size: 14px;
        font-weight: 700;
        margin: 5px 0 15px;
        display: block;
      }

      .message {
        color: #000;

        &:hover {
          color: #004db3;
        }
      }
    }

    .contentUser {
      width: 100%;
      padding: 10px 0 0;

      .commentBody {
        padding-left: 15px;
      }
    }

    .commentFooter {
      display: flex;
      height: 36px;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      border-top: 1px solid #e3e3e3;

      .replyButtons {
        min-width: 100px;

        .buttonReply,
        .buttonToForm {
          border: none;
          background: none;
          cursor: pointer;
          text-align: center;
          text-decoration: none;
          font-size: 12px;
          font-weight: bold;
          padding: 0 8px 0 0;
          vertical-align: middle;
          border-right: 1px solid #e3e3e3 !important;
          color: #003580;
        }

        .buttonNewWide {
          display: none;
        }

        .buttonReply {
          border: none !important;
          margin-left: 8px;
        }
      }

      .rateButtons {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-end;
        min-width: 210px;

        p {
          font-size: 12px;
          color: #666;
          font-weight: 700;
          margin-right: 10px;
        }

        button {
          box-sizing: border-box;
          background: none;
          border: none;
          border-left: 1px solid #e3e3e3;
          height: 36px;
          font-size: 12px;
          width: 65px;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;

          span {
            height: 14px;
            width: 15px;
            display: inline-block;
            margin-right: 10px;
            background-image: url('https://i.dailymail.co.uk/i/furniture/comments/articles/spt_arrows_commentrating_5.png');
          }

          p {
            margin: 0;
          }
        }

        .rateUp {
          p {
            color: #0cac0c;
          }

          span {
            background-position: 0 0;
          }

          &:hover {
            span {
              background-position: 0 -50px;
            }
          }
        }

        .rateDown {
          p {
            color: red;
          }

          span {
            background-position: -50px 0;
          }

          &:hover {
            span {
              background-position: -50px -50px;
            }
          }
        }

        &.alreadyVoted {
          > p {
            color: silver;
          }

          .rateUp,
          .rateDown {
            cursor: not-allowed;

            span {
              background-position: -150px -50px;
            }
          }

          .votedUp {
            background: rgba(12, 172, 12, 0.1);

            span {
              background-position: 0 -50px;
            }
          }

          .votedDown {
            background: rgba(255, 0, 0, 0.1);

            span {
              background-position: -50px -50px;
            }
          }
        }
      }
    }
  }
}

.articleCommentReply {
  background-color: #ebebeb !important;
  margin-left: 70px;

  .commentReplies {
    display: flex;
    flex-flow: column nowrap;
  }

  .articleContentRow {
    .avatar {
      min-width: 35px;
      height: 42px;

      img {
        width: 35px;
        position: absolute;
        clip: rect(0 35px 35px 0);
      }
    }
  }
}

.showRepliesContainer {
  margin-left: 70px;
  background-color: #e3e3e3;
  display: flex;
  justify-content: center;
  padding: 7px;

  p,
  div {
    font-size: 13px !important;
    color: #000;
    font-weight: 700;
  }

  div {
    background: none;
    border: none;
    cursor: pointer;
    color: #003580;
    border-left: 1px solid #000;
    padding-left: 5px;
    margin-left: 5px;

    &:hover {
      text-decoration: underline;
    }

    span {
      display: inline-block;
      background: url(https://i.dailymail.co.uk/i/furniture/comments/articles/spt_arrows_commentrating.png) no-repeat -301px 0;
      width: 20px;
      height: 10px;
      margin-left: 5px;
    }
  }
}
