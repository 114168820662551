.image {
  display: block;
  width: 100%;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100000000;
  background: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div {
    width: 100%;
  }
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.close {
  appearance: none;
  height: 32px;
  width: 32px;
  cursor: pointer;
  background-color: transparent;
  background-image: url(./assets/close.svg);
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0;
  outline: none;
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
}

:global(.kvfysmfp) {
  overflow: visible !important;
  width: 100% !important;
  max-height: 100%;
}

@media (orientation: landscape) {
  :global(.kvfysmfp) {
    width: auto !important;
    height: 100% !important;
  }

  .image {
    width: auto;
    height: 100%;
  }
}
