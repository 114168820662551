@import './variables';

:global(body.mol-desktop) {
  :global(.mol-factbox[data-version="2"]) {
    :global(.mol-factbox-title) {
      background-color: transparent;
    }

    background-color: var(--channel-color-light-background);
    border-top: 0.25 * $baseLine solid var(--channel-color);

    :global(.mol-factbox-title) {
      color: var(--channel-color);
    }

    :global(.mol-factbox-title),
    :global(.imageCaption),
    figcaption {
      border-bottom: 1px solid var(--channel-color);
    }

    .socialButton > svg * {
      fill: var(--channel-color);
    }

    &:global(.wellness) {
      border-color: #b5751a;

      :global(.mol-factbox-title) {
        color: #b5751a;
        border-color: #b5751a;
      }

      :global(.imageCaption) {
        border-color: #b5751a;
      }
    }
  }
}

