@import "../../styles/variables";

.container {
  padding: 1.75 * $baseLine;
  background: #fff;
  border: 0.625 * $baseLine solid #e3e3e3;
  display: block;
  text-align: center;
}

.message {
  font-size: $fontSize;
  line-height: 1.5;
}
