@import "_settings/largeIcons";
@import "_settings/general";

.dms-poll {
  margin: 0 auto;
  width: 264px;

  p {
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #000;
    font-weight: bold;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    text-align: center;

    li {
      padding: 0;
    }
  }

  li {
    background-image: $lgSpriteUrl;
    display: inline-block;
    cursor: pointer;
    width: 40px;
    height: 40px;
    margin-left: 8px;
    padding: 0;
  }

  .facebook {
    background-position: 0 -40px;
    background-repeat: no-repeat;
    box-sizing: border-box;

    &:hover {
      background-position: 0 0;
    }
  }

  .twitter {
    background-image: url('./assets/twitter.svg');
    background-repeat: no-repeat;

    &:hover {
      opacity: 0.8;
    }
  }

  .pinterest {
    background-position: -657px -40px;
    background-repeat: no-repeat;
    box-sizing: border-box;

    &:hover {
      background-position: -657px 0;
    }
  }

  .gplus {
    // delete this after clojure removes gplus from polls
    // PR: https://github.com/MailOnline/clj_fe/pull/1371
    display: none;
  }
}

.poll.poll-container .dms-poll ul {
  text-align: center;
  margin-right: 10px;
  margin-bottom: 10px;
}
