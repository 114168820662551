.wrapper {
  --mol-fe-paywall-modal-bg-primary: #004db3;
  --mol-fe-paywall-modal-bg-primary-dark: #1e3264;
  --mol-fe-paywall-modal-bg-secondary: #fff;
  --mol-fe-paywall-modal-header-text: #fff;
  --mol-fe-paywall-modal-content-text: #1a1a1a;
  --mol-fe-paywall-modal-content-bg: #fff;
  --mol-fe-paywall-modal-button-bg: #004db3;
  --mol-fe-paywall-modal-button-bg-disabled: #999;
  --mol-fe-paywall-modal-button-color: #fff;
  --mol-fe-paywall-modal-footer-text: #1a1a1a;
  --mol-fe-paywall-modal-footer-link: #004db3;

  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999999999;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
  font-family: var(--mol-default-font-family);
  background: rgba(153, 153, 153, 0.8);

  &.thisismoney {
    --mol-fe-paywall-modal-bg-primary: #441243;
    --mol-fe-paywall-modal-bg-primary-dark: #5c395a;
    --mol-fe-paywall-modal-button-bg: #441243;
  }
}

.essentialWrapper {
  // CMP settings modal has z-index set to 10000000
  z-index: 9999999;
}

.modalWrapper {
  width: 100%;
  max-width: 405px;
  border-radius: 16px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.16);
  background: linear-gradient(118deg, var(--mol-fe-paywall-modal-bg-primary-dark) 4.18%, var(--mol-fe-paywall-modal-bg-primary) 64.65%);
}

.modal {
  padding: 35px 20px 16px;
  position: relative;
  border-radius: 16px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-size: 100% 30%;
  background-position: bottom;
}

.header {
  font-size: 30px;
  font-weight: 500;
  color: var(--mol-fe-paywall-modal-header-text);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
  line-height: 1.3;
}

.success,
.error {
  font-size: 18px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1.1;
  padding: 8px 20px;
  color: #fff;
  border-radius: 8px;
}

.success {
  background-color: #00b300;
}

.error {
  background-color: #f33;
}

.content {
  margin-top: 24px;
  margin-bottom: 16px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 16px;
  border-radius: 10px;
  color: var(--mol-fe-paywall-modal-content-text);
  background: var(--mol-fe-paywall-modal-content-bg);
  font-size: 20px;
  font-weight: 600;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.16);
  line-height: 1.3;
}

.button {
  width: 100%;
  padding: 18px 30px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  appearance: none;
  border: none;
  color: var(--mol-fe-paywall-modal-button-color);
  background-color: var(--mol-fe-paywall-modal-button-bg);
  border-radius: 70px;
  cursor: pointer;
  outline: none;
  font-size: 18px;
  font-weight: 500;

  &[disabled] {
    background-color: var(--mol-fe-paywall-modal-button-bg-disabled);
    cursor: not-allowed;
  }

  &.secondary {
    background-color: #fff;
    border: 1.5px solid var(--mol-fe-paywall-modal-button-bg);
    color: var(--mol-fe-paywall-modal-button-bg);

    &[disabled] {
      background-color: #fff;
      border: 1.5px solid var(--mol-fe-paywall-modal-button-bg-disabled);
      color: var(--mol-fe-paywall-modal-button-bg-disabled);
    }

    &:hover {
      opacity: 1;
      background-color: #e6f1ff;
    }
  }

  &:hover {
    opacity: 0.8;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: var(--mol-fe-paywall-modal-footer-text);

  a {
    color: var(--mol-fe-paywall-modal-footer-link);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.closeButton {
  width: 34px;
  height: 34px;
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 0;
  appearance: none;
  border: none;
  background-color: transparent;
  background-image: url(./assets/close.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px 14px;
  cursor: pointer;
  outline: none;
}
