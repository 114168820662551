.openGalleryButton {
  display: block;
  appearance: none;
  border: none;
  height: auto;
  box-sizing: border-box;
  color: #fff;
  background-color: #014eb3;
  padding: 7px;
  font-size: 12px;
  font-weight: normal;
  line-height: 1;
  position: absolute;
  right: 10px;
  bottom: 10px;
  pointer-events: none;
  text-align: left;
  border-radius: 2px;
  font-family: var(--mol-default-font-family);

  &.small {
    font-size: 12px;
  }

  .imageCount {
    font-size: 17px;
    font-weight: 600;
    height: 21px;
    padding-left: 32px;
    width: 100%;
    box-sizing: border-box;
    background: url(./assets/camera.svg) no-repeat left center;
    background-size: 28px 21px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .viewGalleryText {
    font-size: 12px;
    font-weight: normal;
    margin-top: 3px;
  }
}

:global(.image-wrap):hover .openGalleryButton {
  background-color: #105dc3;
}

:global {
  body.enhanced-gallery-open #eyeDiv {
    z-index: 0 !important;
  }
}

[data-is-inline-paywall-mounted] {
  .openGalleryButton {
    display: none !important;
  }
}

:global(.mol-mobile) {
  .openGalleryButton {
    padding: 8px 10px;
    border-radius: 4px;
    background-color: #004db3;

    .imageCount {
      background: url(./assets/imageStack.svg) no-repeat left center;
      background-size: 24px 24px;
      padding-left: 26px;
    }

    .viewGalleryText {
      font-size: 11px;
      margin-top: 2px;
      font-weight: 500;
    }

    .plusSign {
      display: inline-block;
      width: 10px;
      height: 10px;
      background: url(./assets/plus.svg) no-repeat center;
      background-size: 10px 10px;
      margin: 0 2px;
      text-indent: -9999px;
    }
  }
}
